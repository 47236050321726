<template>
  <div class="animated fadeIn">
    <loading :active.sync="isLoading"></loading>
    <div class="container due">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{$t('message.profile_edit')}}</h1>
            <p class="lead">
              {{ form.email }}
            </p>
          </div>
          <form @submit.prevent="submit" class="form">
            <input type="hidden" v-model="form.type"/>
            <input type="hidden" v-model="form.contactNo"/>
            <h4 class="mt-3">{{$t('message.configurations')}}</h4>
            <hr class="mb-3"/>
            <b-row class="mb-5">
              <b-col sm="12">
                <div class="form-group">
                  <b-button @click="cropAvatar.toBeCropped= true" variant="info">{{$t('message.change_avatar_profile')}}</b-button>
                </div>
              </b-col>
              <b-col sm="12">
                <div class="col-sm-12 col-md-2 col-xl-2">
                  <img :src="getAvatar" class="img-avatar" id="avatarImage"/>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <validation-field
                  :label="$t('message.interface_language')"
                  :v="$v.form.Language_Code"
                >
                  <select
                    class="form-control"
                    v-model="form.Language_Code"
                    v-on:blur="$v.form.Language_Code.$touch()"
                  >
                    <option v-for="(language,index) in getLanguages" :value="language.navCode">{{language.label}}</option>
                  </select>
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <validation-field
                  :label="$t('message.password_change_interval')"
                  :v="$v.form.passwordChangeInterval"
                >
                  <select
                    class="form-control"
                    v-model="form.passwordChangeInterval"
                    v-on:blur="$v.form.passwordChangeInterval.$touch()"
                  >
                    <option value="18250">{{$t('message.never')}}</option>
                    <option value="30">30 {{$t('message.days')}}</option>
                    <option value="60">60 {{$t('message.days')}}</option>
                    <option value="90">90 {{$t('message.days')}}</option>
                  </select>
                </validation-field>
              </b-col>
            </b-row>
            <h4 class="mt-3">{{$t('message.profile_data')}}</h4>
            <hr class="mb-3"/>
            <b-row>
              <b-col sm="12">
                <validation-field
                  :label="$t('message.email')"
                  :v="$v.form.email">
                  <input
                    class="form-control" id="email"
                    maxlength="50"
                    type="text"
                    v-model="form.email"
                    v-on:blur="$v.form.email.$touch()"
                  />
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <validation-field
                  :label="$t('message.invoice_email')"
                  :v="$v.form.invoiceEmail"
                >
                  <input
                    class="form-control"
                    id="invoiceEmail"
                    maxlength="50"
                    type="text"
                    v-model="form.invoiceEmail"
                    v-on:blur="$v.form.invoiceEmail.$touch()"
                  />
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="!businessOrPrivate">
              <!-- prima parte nominativo -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.business_fullname')"
                  :v="$v.form.businessName"
                  v-if="!businessOrPrivate"
                >
                  <input
                    :placeholder="$t('message.placeholder_business_fullname')" class="form-control"
                    maxlength="50"
                    type="text"
                    v-model="form.businessName"
                    v-on:blur="$v.form.businessName.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="!businessOrPrivate">
              <!-- seconda parte nominativo -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.business_fullname2')"
                  :v="$v.form.businessName2"
                >
                  <input
                    :placeholder="$t('message.placeholder_business_fullname2')" class="form-control"
                    maxlength="50"
                    type="text" v-model="form.businessName2"
                    v-on:blur="$v.form.businessName2"
                  >

                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="businessOrPrivate">
              <!-- nome -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.first_name')"
                  :v="$v.form.name"
                >
                  <input
                    class="form-control"
                    type="text"
                    maxlength="30"
                    v-model="form.name"
                    v-on:blur="$v.form.name.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="businessOrPrivate">
              <!-- cognome -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.last_name')"
                  :v="$v.form.surname"
                >
                  <input
                    class="form-control"
                    id="surname"
                    type="text"
                    maxlength="30"
                    v-model="form.surname"
                    v-on:blur="$v.form.surname.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- indirizzo -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.address')"
                  :v="$v.form.address"
                >
                  <input
                    class="form-control"
                    id="address"
                    maxlength="50"
                    type="text"
                    v-model="form.address"
                    v-on:blur="$v.form.address.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- indirizzo seconda parte -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.address2')"
                  :v="$v.form.address2"
                >
                  <input
                    class="form-control"
                    maxlength="50"
                    type="text"
                    v-model="form.address2"
                    v-on:blur="$v.form.address2.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- cap -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.post_code')"
                  :v="$v.form.postalCode"
                >
                  <input
                    class="form-control"
                    maxlength="20"
                    type="text"
                    v-model="form.postalCode"
                    v-on:keydown="$v.form.postalCode.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- città -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.city')"
                  :v="$v.form.city"
                >
                  <input
                    class="form-control"
                    maxlength="30"
                    type="text"
                    v-model="form.city"
                    v-on:blur="$v.form.city.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="isIta">
              <!-- provincia -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.county')"
                  :v="$v.form.province"
                  v-if="showProvince"
                >
                  <select class="form-control">
                    <option
                      :selected="county.Code === customer.County"
                      v-bind:value="county.Code"
                      v-for="county in counties"
                    >
                      {{ county.Description}}
                    </option>
                  </select>
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- telefono -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.telephone')"
                  :v="$v.form.phoneNumber"
                >
                  <input
                    :placeholder="$t('message.placeholder_telephone')"
                    class="form-control"
                    maxlength="20"
                    type="text"
                    v-model="form.phoneNumber"
                    v-on:blur="$v.form.phoneNumber.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- mobile -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.mobile')"
                  :v="$v.form.mobileNumber"
                >
                  <input
                    :placeholder="$t('message.placeholder_mobile')"
                    class="form-control"
                    type="text"
                    maxlength="20"
                    v-model="form.mobileNumber"
                    v-on:blur="$v.form.mobileNumber.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <!-- codice fiscale -->
              <b-col sm="12" v-if="showFiscalCode">
                <validation-field
                  :label="$t('message.fiscal_code')"
                  :v="$v.form.fiscalCode"
                >
                  <input
                    class="form-control"
                    maxlength="20"
                    readonly="readonly"
                    type="text"
                    v-model="form.fiscalCode"
                    v-on:blur="$v.form.fiscalCode.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="!businessOrPrivate">
              <!-- partita iva -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.vat_number')"
                  :v="$v.form.businessCode"
                >
                  <input
                    class="form-control"
                    type="text"
                    maxlength="20"
                    v-model="form.businessCode"
                    readonly="readonly"
                    v-on:blur="$v.form.businessCode.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="!businessOrPrivate && showSdiCode">
              <!-- codice SDI -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.sdi_code')"
                  :v="$v.form.sdiCode"
                >
                  <input
                    class="form-control"
                    readonly="readonly"
                    type="text"
                    maxlength="7"
                    v-model="form.sdiCode"
                    v-on:blur="$v.form.sdiCode.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row v-if="!businessOrPrivate && showPec">
              <!-- PEC -->
              <b-col sm="12">
                <validation-field
                  :label="$t('message.pec')"
                  :v="$v.form.pec"
                >
                  <input
                    class="form-control"
                    readonly="readonly"
                    type="text"
                    maxlength="250"
                    v-model="form.pec"
                    v-on:blur="$v.form.pec.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <hr class="mb-4">
            <b-row class="">
              <b-col md="6" sm="12" xl="4">
                <b-button
                  :class="{invalid:$v.form.$invalid}"
                  block
                  type="submit"
                  class="bg-theme-secondary"
                >
                  {{$t('message.save')}}
                </b-button>
              </b-col>
              <b-col md="6" sm="12" xl="4">
                <router-link
                  :to="{name : 'products'}"
                >
                  <b-button
                    block
                    type="button"
                    variant="secondary"
                  >
                    {{$t('message.close')}}
                  </b-button>
                </router-link>
              </b-col>
            </b-row>
          </form>
        </div>
      </div>
    </div>

    <b-modal
      :cancel-title="$t('message.cancel')"
      :ok-title="$t('message.save')"
      :title="$t('message.edit_avatar')"
      @cancel="resetAvatar"
      @ok="updateAvatar"
      size="lg"
      v-model="cropAvatar.toBeCropped"
    >
      <b-row>
        <b-col md="12" sm="12" xl="12">
          <div class="box" v-if="showAvatarDropArea" v-on:dragover="dragHandler" v-on:drop="dropHandler">
            <div class="box__input">
              <input
                @change="uploadImage(e)"
                accept="image/*"
                class="box__file"
                id="avatar"
                type="file"
              />
              <div class="text-center">
                <label for="avatar">
                  <strong>{{$t('message.drop_image')}}</strong>
                  <span class="box__dragndrop"> or drag it here</span>.
                </label>
                <button class="box__button" type="submit">Upload</button>
              </div>
            </div>
            <div class="box__uploading">Uploading&hellip;</div>
            <div class="box__success">Done!</div>
            <div class="box__error">Error! <span></span>.</div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!showAvatarDropArea">
        <b-col cl="9" md="9" sm="12">
          <cropper
            :src="cropAvatar.originalDataUrl"
            :stencil-props="{
		          aspectRatio: 1
	          }"
            @change="changeCrop"
            classname="cropper"
          ></cropper>
        </b-col>
        <b-col cl="3" md="3" sm="12">
          <div class="mb-1">
            <label>{{$t('message.preview')}}</label>
          </div>
          <img
            :src="cropAvatar.previewDataUrl"
            class="img-avatar"
            v-if="this.cropAvatar.showPreview"
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
  import ApiResolver from '../../components/common/ApiResolver';
  import Api from '../../components/common/Api';
  import {helpers, minLength, required,email} from 'vuelidate/lib/validators'
  import ValidationField from "../../components/ui/ValidationField";
  import Loading from 'vue-loading-overlay';
  //import Cropper from "../components/ui/Cropper";
  import {Cropper} from 'vue-advanced-cropper'
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import I18n from "../../components/i18n/I18n";

  const isFiscalCodeValid = helpers.regex('alpha', /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/);
  const isVatValid = helpers.regex('alpha', /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/);

  export default {
    name: "Profile",
    components: {
      ValidationField,
      Loading,
      Cropper
    },
    data() {
      return {
        counties: {},
        customer: {},
        fullName: null,
        isLoading: false,
        form: {
          email: null,
          invoiceEmail: null,
          type: null,
          country: null,
          businessName: null,
          businessName2: null,
          name: null,
          surname: null,
          address: null,
          address2: null,
          city: null,
          province: null,
          phoneNumber: null,
          mobileNumber: null,
          salespersonCode: 'LL',
          businessCode: null,
          fiscalCode: null,
          postalCode: null,
          passwordChangeInterval: null,
          contactNo: null,
          Language_Code: null
        },
        avatar: '',
        showAvatarDropArea: true,
        cropAvatar: {
          toBeCropped: false,
          showPreview: false,
          originalDataUrl: '',
          previewDataUrl: ''
        }
      }
    },
    computed: {
      getLanguages: function () {
        return I18n.getLangs();
      },
      getAvatar: function () {
        return this.avatar;
      },
      showProvince: function () {
        if (this.isIta) {
          return true;
        }
        return false;
      },
      businessOrPrivate: function () {
        return this.customer.Individual_Person;
      },
      isBusiness: function () {
        //console.log(parseInt(this.form.type) === 0 ? 'isBusiness' : 'notIsBusiness');
        //console.log(this.form.type);
        return this.form.type === '0';
      },
      isNoProfit: function () {
        return this.form.type === '2';
      },
      isPublic: function () {
        return this.form.type === '3';
      },
      isPrivate: function () {
        return this.form.type === '1';
      },
      showPostalCode: function () {
        if (this.isIta) {
          return true;
        }
        return false;
      },
      showFiscalCode: function () {
        if (this.isIta) {
          return true;
        }
        return false;
      },
      showPec: function () {
        if (this.isIta && this.businessOrPrivate ) {
          return true;
        }
        return false;
      },
      showSdiCode: function () {
        if (this.isIta && this.businessOrPrivate) {
          return true;
        }
        return false;
      },
      isIta: function () {
        return this.customer.Country_Region_Code === 'IT';
      }
    },
    validations: {
      form: {
        businessName: {
          isFullNameRequired(value) {
            if (!value && !this.businessOrPrivate) {
              return false;
            }
            return true;
          }
        },
        businessName2: {},
        name: {
          isNameRequired(value) {
            if (!value && this.businessOrPrivate) {
              return false;
            }
            return true;
          },
          min: minLength(2)
        },
        surname: {
          isSurnameRequired(value) {
            if (!value && this.businessOrPrivate) {
              return false;
            }
            return true;
          },
          min: minLength(2)
        },
        city: {
          required,
        },
        email: {
          required: required,
          email
        },
        address: {
          required
        },
        address2: {},
        postalCode: {
          isPostalCodeRequired(value) {
            if (!value && this.isIta) {
              return false;
            }
            return true;
          },
          isPostCodeToBeValidated(value) {
            if(value && this.isIta  && !value.match(/\b\d{5}\b/)) {
              return false;
            }
            return true;
          }
        },
        province: {
          isProvinceRequired(value) {
            if (!value && this.isIta) {
              return false;
            }
            return true;
          }
        },
        phoneNumber: {},
        mobileNumber: {},
        fiscalCode: {
          isFiscalCodeRequired(value) {
            if (!value && ( this.isPrivate|| this.isNoProfit ) && this.isIta) {
              return false;
            }
            return true;
          },
          isFiscalCodetoBeValidated(value) {
            if(value && this.isIta && !value.match(/(^[a-zA-Z0-9]{11}|^[a-zA-Z0-9]{16})$/)) {
              return false;
            }
            return true;
          },
        },
        passwordChangeInterval: {
          required: true
        },
        businessCode: {
          isBusinessCodeRequired(value) {
            if (!value && ( this.isBusiness || this.isPublic ) && this.isIta) {
              return false;
            }
            return true;
          },
          isBusinessCodetoBeValidated(value) {
            if(value && this.isIta && !value.match(/\b\d{11}\b/)) {
              return false;
            }
            return true;
          },
        },
        pec: {},
        sdiCode: {},
        Language_Code: {
          required: required
        },
        invoiceEmail: {}
      }
    },
    methods: {
      saveAvatar() {
        Api
          .put(ApiResolver.get('AVATAR'), JSON.stringify({
            avatar: this.avatar
          }))
          .then(response => {
            this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.avatar_updated')
            });
          })
          .catch(reason => {
            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: this.$t(reason.title) + ' : ' + this.$t(reason.detail)
            });
          });

        document.getElementById('miniAvatar').src = this.avatar;
      },
      updateAvatar() {
        this.avatar = this.cropAvatar.previewDataUrl;
        this.saveAvatar();
        this.resetAvatar();
        this.showAvatarDropArea = true;
      },
      resetAvatar() {
        this.cropAvatar = {
          toBeCropped: false,
          showPreview: false,
          originalDataUrl: '',
          previewDataUrl: '',
        }
        this.showAvatarDropArea = true;
      },
      changeCrop({coordinates, canvas}) {
        this.cropAvatar.showPreview = false;
        if (canvas) {
          this.cropAvatar.previewDataUrl = canvas.toDataURL();
          setTimeout(() => {
            this.cropAvatar.showPreview = true;
          }, 200)
        }
      },
      submit() {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
        if (this.form.country !== 'IT') {
          this.form.province = 'ZB12';
        }
        this.cropAvatar.toBeCropped = false;
        Api
          .put(ApiResolver.get('PROFILE_EDIT'), JSON.stringify(this.form))
          .then(response => {
            this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.save_successfull')
            });
            //this.$router.push({name:'products'})
          })
          .catch(reason => {
            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: reason.title + ' : ' + reason.detail
            });
          });
      },
      uploadImage: function () {
        const file = document
          .querySelector('input[type=file]')
          .files[0];
        if(file.size > 524288) {
          this.$store.dispatch('alerts/setAlert', {
            type: 'danger',
            text: this.$t('message.file_too_big')
          });
          return false;
        }
        let reader = new FileReader();
        reader.onload = (e) => {
          this.showAvatarDropArea = false;
          this.cropAvatar.showPreview = false;
          this.cropAvatar.toBeCropped = true;
          this.cropAvatar.originalDataUrl = e.target.result;
          this.cropAvatar.previewDataUrl = e.target.result;
        };
        reader.onerror = function (error) {
          alert(error);
        };
        reader.readAsDataURL(file);
      },
      dropHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        const droppedFile = e.dataTransfer.files[0];
        if(droppedFile.size > 524288) {
          this.$store.dispatch('alerts/setAlert', {
            type: 'danger',
            text: this.$t('message.file_too_big')
          });
          return false;
        }
        /**
         * TODO
         * Gestire TIPO file + upload multipli, deve andare solo in primo (o l'ultimo)
         */
        var reader = new FileReader();
        reader.readAsBinaryString(droppedFile);
        reader.onload = () => {
          this.showAvatarDropArea = false;
          this.cropAvatar.showPreview = false;
          this.cropAvatar.toBeCropped = true;
          this.cropAvatar.originalDataUrl = "data:application/octet-stream;base64," + btoa(reader.result);
          this.cropAvatar.previewDataUrl = "data:application/octet-stream;base64," + btoa(reader.result);
        };
        reader.onerror = function () {
          //console.log('there are some problems');
        };
      },
      dragHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        const droppedFile = e.dataTransfer.files[0];
        if(droppedFile.size > 524288) {
          this.$store.dispatch('alerts/setAlert', {
            type: 'danger',
            text: this.$t('message.file_too_big')
          });
          return false;
        }
        /**
         * TODO
         * Gestire TIPO file + upload multipli, deve andare solo in primo (o l'ultimo)
         */
        var reader = new FileReader();
        reader.readAsBinaryString(droppedFile);
        reader.onload = () => {
          this.showAvatarDropArea = false;
          this.cropAvatar.showPreview = false;
          this.cropAvatar.toBeCropped = true;
          this.cropAvatar.originalDataUrl = "data:application/octet-stream;base64," + btoa(reader.result);
          this.cropAvatar.previewDataUrl = "data:application/octet-stream;base64," + btoa(reader.result);
        };
        reader.onerror = function () {
          //console.log('there are some problems');
        };
      },
    },
    mounted() {
      this.isLoading = true;
      Api
        .get(ApiResolver.get('COUNTIES_LIST'))
        .then(response => {
          this.counties = response.data;
        })
        .catch(reason => {
          this.isLoading = false;
        });
      Api
        .get(ApiResolver.get('AVATAR'))
        .then(response => {
          this.avatar = response.data;
        })
        .catch(reason => {
          this.isLoading = false;
          this.$store.dispatch('alerts/setAlert', {
            type: 'danger',
            text: reason.title + ' : ' + reason.detail
          });
        });
      Api
        .get(ApiResolver.get('PROFILE'))
        .then(response => {
          //console.log(response.data);
          let customer = response.data;
          this.customer = customer;
          let type = customer.Individual_Person === true ? '1' : '0';
          this.form = {
            type: type,
            country: customer.Country_Region_Code,
            businessName: customer.Name,
            businessName2: customer.Name2,
            name: customer.First_Name,
            surname: customer.Last_Name,
            address: customer.Address,
            address2: customer.Address2,
            city: customer.City,
            province: customer.County,
            phoneNumber: customer.Phone_No,
            mobileNumber: customer.Mobile_Phone_No,
            salespersonCode: 'LL',
            postalCode: customer.Post_Code,
            fiscalCode: customer.Fiscal_Code,
            businessCode: customer.VAT_Registration_No,
            contactNo: customer.Primary_Contact_No,
            passwordChangeInterval: customer.Password_Change_Interval,
            email: customer.E_Mail,
            invoiceEmail: customer.eMail_Ricezione_Fatture,
            Language_Code: customer.Language_Code,
          };
          this.isLoading = false;
        })
        .catch(reason => {
          this.isLoading = false;
          this.$store.dispatch('alerts/setAlert', {
            type: 'danger',
            text: reason.title + ' : ' + reason.detail
          });
        });
    }
  }
</script>

<style scoped>
  .hasError label {
    color: red;
  }

  .error {
    color: red
  }

  .box {
    font-size: 1.25rem; /* 20 */
    background-color: #efefef;
    position: relative;
    padding: 100px 20px;
    border: 2px dashed #ccc;
  }

  .box.has-advanced-upload {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;

    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
  }

  .box.is-dragover {
    outline-offset: -20px;
    outline-color: #c8dadf;
    background-color: #fff;
  }

  .box__dragndrop,
  .box__icon {
    display: none;
  }

  .box.has-advanced-upload .box__dragndrop {
    display: inline;
  }

  .box.has-advanced-upload .box__icon {
    width: 100%;
    height: 80px;
    fill: #92b0b3;
    display: block;
    margin-bottom: 40px;
  }

  .box.is-uploading .box__input,
  .box.is-success .box__input,
  .box.is-error .box__input {
    visibility: hidden;
  }

  .box__uploading,
  .box__success,
  .box__error {
    display: none;
  }

  .box.is-uploading .box__uploading,
  .box.is-success .box__success,
  .box.is-error .box__error {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .box__uploading {
    font-style: italic;
  }

  .box__success {
    -webkit-animation: appear-from-inside .25s ease-in-out;
    animation: appear-from-inside .25s ease-in-out;
  }

  @-webkit-keyframes appear-from-inside {
    from {
      -webkit-transform: translateY(-50%) scale(0);
    }
    75% {
      -webkit-transform: translateY(-50%) scale(1.1);
    }
    to {
      -webkit-transform: translateY(-50%) scale(1);
    }
  }

  @keyframes appear-from-inside {
    from {
      transform: translateY(-50%) scale(0);
    }
    75% {
      transform: translateY(-50%) scale(1.1);
    }
    to {
      transform: translateY(-50%) scale(1);
    }
  }

  .box__restart {
    font-weight: 700;
  }

  .box__restart:focus,
  .box__restart:hover {
    color: #39bfd3;
  }

  .box__file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .box__file + label {
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }

  .box__file + label:hover strong,
  .box__file:focus + label strong,
  .box__file.has-focus + label strong {
    color: #39bfd3;
  }

  .box__file:focus + label,
  .box__file.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  .box__file + label * {
    /* pointer-events: none; */ /* in case of FastClick lib use */
  }

  .no-js .box__file + label {
    display: none;
  }

  .no-js .box__button {
    display: block;
  }

  .box__button {
    font-weight: 700;
    color: #e5edf1;
    background-color: #39bfd3;
    display: none;
    padding: 8px 16px;
    margin: 40px auto 0;
  }

  .box__button:hover,
  .box__button:focus {
    background-color: #0f3c4b;
  }

</style>


